import { scoreToColor } from "utils"

export const SCORES = [
  {
    score: 0,
    description: "None",
    scoreColor: scoreToColor(0),
  },
  {
    score: 20,
    description: "Poor",
    scoreColor: scoreToColor(20),
  },
  {
    score: 40,
    description: "Needs Improvement",
    scoreColor: scoreToColor(40),
  },
  {
    score: 60,
    description: "Average",
    scoreColor: scoreToColor(60),
  },
  {
    score: 80,
    description: "Very Good",
    scoreColor: "#5EE282",
  },
  {
    score: 100,
    description: (
      <div>
        Best
        <div>in Class</div>
      </div>
    ),
    scoreColor: scoreToColor(100),
  },
]
