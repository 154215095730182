import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import { RootState } from "../../store"
import { CatalogRequestOptions } from "../../common/models/CatalogRequestOptions"
import { PageModel } from "../../common/models/PageModel"
import { ItemDashboardFilterModel } from "../../common/models/ItemDashboardFilterModel"
import { DashboardFilterModel } from "../../common/models/DashboardFilterModel"
import { LINK_CLASS_NAME } from "utils/constants"

export const getHealthScoreRequestOptions = (
  dataFilters: DashboardFilterModel | null,
  isMyBrand: boolean = false,
  auditCatalog: RootState["auditCatalog"],
) => {
  const requestOptions = new CatalogRequestOptions({
    pageModel: new PageModel({ ...auditCatalog.requestOptions.pageModel }),
    itemDashboardFilterModel: new ItemDashboardFilterModel({
      tags: isMyBrand ? ["My Brands"] : [],
      filterScore: null,
      filter: dataFilters
        ? new DashboardFilterModel({
            cardType: dataFilters.cardType,
            values: dataFilters.values,
          })
        : null,
    }),
    itemFilterModel: null,
  })
  return requestOptions
}

export const getHealthScoreColumns = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const auditCatalog = useSelector((state: RootState) => state.auditCatalog)

  const onValueClick = (
    dataFilters: DashboardFilterModel | null,
    isMyBrand: boolean = false,
  ) => {
    const requestOptions = getHealthScoreRequestOptions(
      dataFilters,
      isMyBrand,
      auditCatalog,
    )
    dispatch.auditCatalog.setRequestOptions(requestOptions)

    return navigate("/main/audit-catalog")
  }

  return [
    {
      id: 1,
      header: "",
      accessor: "title",
      width: "50%",
      flexGrow: 1,
      isSortable: true,
      cell: ({ row: { original: data } }: any) => (
        <span title={data.title}>{data.title}</span>
      ),
    },
    {
      id: 2,
      header: "Brand",
      accessor: "myBrand",
      width: "25%",
      isSortable: true,
      cell: ({ row: { original: data } }: any) => {
        return (
          <span
            className={LINK_CLASS_NAME}
            onClick={() => onValueClick(data.filters, true)}
          >
            {data?.myBrand || ""}
          </span>
        )
      },
    },
    {
      id: 3,
      header: "Comp",
      accessor: "competitorSku",
      width: "25%",
      isSortable: true,
      cell: ({ row: { original: data } }: any) => {
        return (
          <span className={LINK_CLASS_NAME} onClick={() => onValueClick(data.filters)}>
            {data?.competitorSku || ""}
          </span>
        )
      },
    },
  ]
}

export const getAttributesColumns = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const auditCatalog = useSelector((state: RootState) => state.auditCatalog)

  const onValueClick = (
    dataFilters: DashboardFilterModel | null,
    isMyBrand: boolean = false,
  ) => {
    const requestOptions = new CatalogRequestOptions({
      pageModel: new PageModel({ ...auditCatalog.requestOptions.pageModel }),
      itemDashboardFilterModel: new ItemDashboardFilterModel({
        tags: isMyBrand ? ["My Brands"] : [],
        filterScore: null,
        filter: dataFilters
          ? new DashboardFilterModel({
              cardType: dataFilters.cardType,
              values: dataFilters.values,
            })
          : null,
      }),
      itemFilterModel: null,
    })
    dispatch.auditCatalog.setRequestOptions(requestOptions)

    return navigate("/main/audit-catalog")
  }

  return [
    {
      id: 1,
      header: "",
      accessor: "title",
      width: "50%",
      isSortable: true,
      cell: ({ row: { original: data } }: any) => (
        <span className={data.rowClassName ?? ""} title={data.title}>
          {data.title}
        </span>
      ),
    },
    {
      id: 2,
      header: "Count",
      accessor: "myBrand",
      width: "25%",
      isSortable: true,
      cell: ({ row: { original: data } }: any) => {
        return (
          <span
            className={LINK_CLASS_NAME}
            onClick={() => onValueClick(data.filters, true)}
          >
            {data?.myBrand || ""}
          </span>
        )
      },
    },
    {
      id: 3,
      header: "%",
      accessor: "myBrandAdditionalValue",
      width: "25%",
      isSortable: true,
      cell: ({ row: { original: data } }: any) => {
        return (
          <span
            className={LINK_CLASS_NAME}
            onClick={() => onValueClick(data.filters, true)}
          >
            {data?.myBrandAdditionalValue || ""}
          </span>
        )
      },
    },
  ]
}

export const getProductExperienceColumns = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const auditCatalog = useSelector((state: RootState) => state.auditCatalog)

  const onValueClick = (
    dataFilters: DashboardFilterModel | null,
    isMyBrand: boolean = false,
    filterScore: number | null = null,
  ) => {
    const requestOptions = new CatalogRequestOptions({
      pageModel: new PageModel({ ...auditCatalog.requestOptions.pageModel }),
      itemDashboardFilterModel: new ItemDashboardFilterModel({
        tags: isMyBrand ? ["My Brands"] : [],
        filterScore: filterScore,
        filter: dataFilters
          ? new DashboardFilterModel({
              cardType: dataFilters.cardType,
              values: dataFilters.values,
            })
          : null,
      }),
      itemFilterModel: null,
    })
    dispatch.auditCatalog.setRequestOptions(requestOptions)

    return navigate("/main/audit-catalog")
  }

  return [
    {
      id: 1,
      header: "",
      accessor: "title",
      width: "50%",
      isSortable: true,
      cell: ({ row: { original: data } }: any) => (
        <span className={data.rowClassName ?? ""} title={data.title}>
          {data.title}
        </span>
      ),
    },
    {
      id: 2,
      header: "< 40",
      accessor: "myBrand",
      width: "25%",
      isSortable: true,
      cell: ({ row: { original: data } }: any) => {
        return (
          <span
            className={LINK_CLASS_NAME}
            onClick={() => onValueClick(data.filters, true, 2)}
          >
            {data?.myBrand || ""}
          </span>
        )
      },
    },
    {
      id: 3,
      header: "< 60",
      accessor: "myBrandAdditionalValue",
      width: "25%",
      isSortable: true,
      cell: ({ row: { original: data } }: any) => {
        return (
          <span
            className={LINK_CLASS_NAME}
            onClick={() => onValueClick(data.filters, true, 3)}
          >
            {data?.myBrandAdditionalValue || ""}
          </span>
        )
      },
    },
  ]
}
